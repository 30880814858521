.news a {
  color: #1d6354;
}

.news a:hover {
  color: #000;
}

.news p {
  margin-top: '10px';
}

.news .row {
  margin-bottom: '30px';
}
