@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap&subset=vietnamese);
.navbar li>.active {
  color:#29282b;
}

.bottom-invitation {
  margin-bottom: 100px;
}

.downloads, .intro {
  text-align: center;
  font-size: 0;
}

.downloads a img {
  -webkit-transition: transform 0.5s;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  width: 200px;
  height: 61px;
}

.downloads a {
  margin: 3px;
  display: inline-block;
}

.downloads a:hover img {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
}

.green-page {
  background-image: url('/images/green/bg.jpg');
}

.escrow-logo-item {
  height: 100px;
  margin-top: 30px;
}

.news a {
  color: #1d6354;
}

.news a:hover {
  color: #000;
}

.news p {
  margin-top: '10px';
}

.news .row {
  margin-bottom: '30px';
}

.custom-container {
  padding: 0 150px;
}

.green-page {
  background-image: url('/images/green/bg.jpg');
  color: black;
  font-size: 25px;
}

.green-page * {
  font-family: 'Roboto'
}

.green-heading {
  font-size: 100px;
  margin-top: 100px
}


@media screen and (max-width: 480px) {
  .custom-container {
    padding: 10px;
  }

  .green-heading {
    font-size: 50px;
  }
}

.version {
  position: fixed;
  left: 0px;
  top: 100px;
  font-size:30px;
  color: #A4C828;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  z-index: 500;
  cursor: pointer;
}

.version:hover,.version:focus{
  color: #B6DD2A;
}

.comments {
  background-image: url('/images/green/bg.jpg');
}

