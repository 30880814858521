.bottom-invitation {
  margin-bottom: 100px;
}

.downloads, .intro {
  text-align: center;
  font-size: 0;
}

.downloads a img {
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -ms-transition: transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
  width: 200px;
  height: 61px;
}

.downloads a {
  margin: 3px;
  display: inline-block;
}

.downloads a:hover img {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
}

.green-page {
  background-image: url('/images/green/bg.jpg');
}
