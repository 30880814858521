@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap&subset=vietnamese);

.custom-container {
  padding: 0 150px;
}

.green-page {
  background-image: url('/images/green/bg.jpg');
  color: black;
  font-size: 25px;
}

.green-page * {
  font-family: 'Roboto'
}

.green-heading {
  font-size: 100px;
  margin-top: 100px
}


@media screen and (max-width: 480px) {
  .custom-container {
    padding: 10px;
  }

  .green-heading {
    font-size: 50px;
  }
}
