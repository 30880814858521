.version {
  position: fixed;
  left: 0px;
  top: 100px;
  font-size:30px;
  color: #A4C828;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  z-index: 500;
  cursor: pointer;
}

.version:hover,.version:focus{
  color: #B6DD2A;
}
